<template>
  <div class="profile-section">
    <div class="category-title align-center">
      <v-img
        v-if="!$vuetify.breakpoint.xs"
        dark
        src="/my-account.jpg"
        height="170"
        class="elevation-0 rounded-0"
      >
        <div class="layer"></div>
        <h1 class="pt-16 text-center">
          {{ $t("navbar.navigation.title") }}
        </h1>
      </v-img>
      <v-container v-else class="pb-0">
        <h1>
          {{ $t("navbar.navigation.title") }}
        </h1>
      </v-container>
    </div>
    <Breadcrumb :items="breadCrumbs" class="custom-breadcrumb" />
    <v-container>
      <!-- <router-view></router-view> -->
      <ProfileUpdate />
    </v-container>
  </div>
</template>

<script>
import Breadcrumb from "@/components/navigation/Breadcrumb";
import ProfileUpdate from "./ProfileUpdate.vue";

export default {
  name: "Profile",
  components: {
    Breadcrumb,
    ProfileUpdate
  },
  computed: {
    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const arrFiltered = pathArray.filter(el => {
        return el != null && el != "";
      });
      const breadCrumbs = [{ href: "/", text: "Homepage" }];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = "";
      // let lastIndexFound = 0;
      for (let i = 0; i < arrFiltered.length; ++i) {
        breadcrumb = `${breadcrumb}${"/"}${arrFiltered[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
        ) {
          breadCrumbs.push({
            href: breadcrumb,
            // i !== 0 && pathArray[i - (i - lastIndexFound)]
            //   ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
            //   : breadcrumb,
            disabled: i + 1 === arrFiltered.length,
            text: this.$route.matched[i].meta.breadCrumb || arrFiltered[i]
          });
          // lastIndexFound = i;
          // breadcrumb = "";
        }
      }
      return breadCrumbs;
    }
  }
};
</script>
